<template>
  <header>

    <div class="avatar">
      <img  @click="showPreview" :src="userInfo.userImg" onerror="this.onerror=null;this.src='@/assets/images/mine/icon-default.jpg'"  alt="" />
     <!--onerror="this.onerror=null;this.src='@/assets/images/mine/icon-ren.png'"  -->
    </div>
    <div class="user-name">
      <p>{{ userInfo.nickName }}</p>
      <!--<p>{{ userInfo.personalSignature }}</p>-->
    </div>

  </header>

  <main>
    <ul class="menu border-bottom">
      <li class="menu-li">
        <router-link :to="{ name: 'Order' }" class="nei-order">
          <img  src="@/assets/images/mine/icon-dd.png" alt="" />
          <!-- @/assets/images/mine/icon-dd.png -->
          我的订单
        </router-link>
      </li>
      <li class="menu-li">
        <router-link :to="{ name: 'Bill' }" class="user-account">
          <img  src="@/assets/images/mine/icon-zd.png" alt="" />
          我的账单
        </router-link>
      </li>
      <li class="menu-li">
        <router-link :to="{ name: 'Invoice' }" class="user-account">
          <img   src="@/assets/images/mine/icon-fp.png" alt="" />
          我的发票
        </router-link>
      </li>
      <li class="menu-li">
        <router-link :to="{ name: 'Intergral' }" class="user-account">
          <img src="@/assets/images/mine/icon-jf.png" alt="" />
          我的积分
        </router-link>
      </li>
    </ul>
    <ul class="menu menu-wapper">
      <li class="menu-li">
        <router-link
          :to="{
            name: 'Personal',
            query: { uid: userInfo.id, type: 'mine' },
          }"
        >
          <img src="@/assets/images/mine/icon-zy.png" alt="" />
          我的主页
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
          :to="{
            name: 'UserInfo',
          }"
        >
          <img src="@/assets/images/mine/icon-zl.png" alt="" />
          我的资料
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
          :to="{
            name: 'ShopCar',
          }"
        >
          <img src="@/assets/images/mine/icon-gwc.png" alt="" />
          购物车
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
          :to="{
            name: 'Message',
          }"
        >
          <img src="@/assets/images/mine/icon-xx.png" alt="" />
          我的消息
        </router-link>
      </li>

      <li class="menu-li">
        <router-link
          :to="{
            name: 'Attention',
            query: {
              uid: userInfo.id,
            },
          }"
        >
          <img src="@/assets/images/mine/icon-gz.png" alt="" />
          我的关注
        </router-link>
      </li>

      <li class="menu-li">
        <router-link
          :to="{
            name: 'Unsubscribe',
               query: {
              uid: userInfo.id,
            },
          }"
        >
          <img src="@/assets/images/mine/icon-fs.png" alt="" />
          我的粉丝
        </router-link>
      </li>
      <!-- <li class="menu-li">
        <router-link
          :to="{
            name: 'Address',
          }"
        >
          <img src="@/assets/images/mine/icon-dz.png" alt="" />
          我的地址
        </router-link>
      </li> -->
      <li class="menu-li">
        <router-link
          :to="{
            name: 'Collection',
          }"
        >
          <img src="@/assets/images/mine/icon-sc.png" alt="" />
          我的收藏
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
          :to="{
            name: 'Account',
          }"
        >
          <img src="@/assets/images/mine/icon-gl.png" alt="" />
          账号管理
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
          :to="{
            name: 'Prize',
          }"
        >
          <img src="@/assets/images/mine/icon-jp.png" alt="" />
          我的奖品
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
          :to="{
            name: 'signIn',
          }"
        >
          <img src="@/assets/images/mine/icon-rw.png" alt="" />
          我的任务
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
          :to="{
            name: 'Suggest',
          }"
        >
          <img src="@/assets/images/mine/icon-jy.png" alt="" />
          建议投诉
        </router-link>
      </li>

      <li class="menu-li">
        <router-link
                :to="{
              name: 'Coupon',
               query: { uid: userInfo.id, type: 'mine' }
            }"
        >
          <img src="@/assets/images/mine/icon-yhq.png"  alt="" />
          优惠券
        </router-link>
      </li>
    </ul>
    <preview
      v-if="userInfo && userInfo.userImg && previewing"
      @close="closePreview"
      :imgList="[{ src: userInfo.userImg }]"
    ></preview>
  </main>
  <fixed-footer></fixed-footer>
</template>


<script lang="ts">
import { defineComponent, inject, ref } from "vue";

import fixedFooter from "@/components/common/Footer.vue";

import axios from "@/api/axios";

import Toast from "@/components/UI/Toast";
import Preview from "@/components/UI/Preview";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import Modal from "@/components/UI/Modal";
import { wechatLoginMine } from "@/api/wechatLogin";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "Mine",
  components: {
    fixedFooter,
    Preview,
  },
  setup() {
    //  展示用户信息
    const userInfo = inject("userInfo") as any;
    const route = useRoute();
    const UPDATE_USERINFO = inject("UPDATE_USERINFO") as (data: object) => void;
    function handleUserInfo(data: object) {
      UPDATE_USERINFO(data);
    }
    function updateToken(token: string) {
      localStorage.setItem("token", token);
    }
    wechatLoginMine(route.query.code as string, route.query.state as string).then((res: any) => {
      if (typeof res === "object" && res.data.success) {
        updateToken(res.data.token);
        handleUserInfo(res.data.obj);
      }
    })
    onBeforeRouteLeave((to, from) => {
      if (new Date().getHours() < 8 || new Date().getHours() >= 23) {
        if (
          to.name == "Order" ||
          to.name == "Invoice" ||
          to.name == "Bill" ||
          to.name == "Intergral"
        ) {
          Modal({
            title: "温馨提醒",
            content: "23:00-8:00期间此功能正在维护中...",
          });
          return false;
        }
      }
    });
    //  预览大图
    const previewing = ref(false);
    function closePreview() {
      previewing.value = false;
    }
    function showPreview() {
      previewing.value = true;
    }
    return {
      userInfo,
      previewing,
      showPreview,
      closePreview,
    };
  },
});
</script>


<style lang="scss" scoped>
header {
  width: 100%;
  height: 230px;
  background: url("../assets/images/mine/bg.jpg") no-repeat;
  background-size:100% 100%;
  padding-top: 25px;
  box-sizing: border-box;
  padding-left: 0 !important;
}

.avatar {
  margin: 0 auto;

  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-bottom: 10px;
  img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 2.5px solid #fff;
  }
}

.user-name {
  text-align: center;
  color: #fff;
  font-size: 14px;
  p {
    margin: 5px 0;
  }
}

.menu {
  box-sizing: border-box;
  padding: 0 10px;
  overflow: hidden;
  padding-bottom: 15px;
  .menu-li {
    float: left;
    width: 25%;
    height: 2.5rem;
    text-align: center;
    padding-top: 17.5px;
    a {
      color: #444444;
      font-size: 14px;
      display: block;
    }
    img {
      /* width: 25px; */
      height: 22px;
      margin: 0 auto;
      margin-bottom: 12.5px;
      display: block;
    }
  }
}

.menu-wapper {
  padding-bottom: 100px;
  margin-top:10px;
}

.border-bottom {
  //border-bottom: 4px solid #f4f4f4;
  border: 3px solid white;
  background-color: white;
  border-radius: 13px;
  width:95%;
  height: 2.5rem;
  box-shadow: 0px 0px 5px #888888;
  position:fixed;
  top:140px;
  left:2.5%;

}
</style>
