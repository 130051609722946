
import { defineComponent, inject, ref } from "vue";

import fixedFooter from "@/components/common/Footer.vue";

import axios from "@/api/axios";

import Toast from "@/components/UI/Toast";
import Preview from "@/components/UI/Preview";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import Modal from "@/components/UI/Modal";
import { wechatLoginMine } from "@/api/wechatLogin";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "Mine",
  components: {
    fixedFooter,
    Preview,
  },
  setup() {
    //  展示用户信息
    const userInfo = inject("userInfo") as any;
    const route = useRoute();
    const UPDATE_USERINFO = inject("UPDATE_USERINFO") as (data: object) => void;
    function handleUserInfo(data: object) {
      UPDATE_USERINFO(data);
    }
    function updateToken(token: string) {
      localStorage.setItem("token", token);
    }
    wechatLoginMine(route.query.code as string, route.query.state as string).then((res: any) => {
      if (typeof res === "object" && res.data.success) {
        updateToken(res.data.token);
        handleUserInfo(res.data.obj);
      }
    })
    onBeforeRouteLeave((to, from) => {
      if (new Date().getHours() < 8 || new Date().getHours() >= 23) {
        if (
          to.name == "Order" ||
          to.name == "Invoice" ||
          to.name == "Bill" ||
          to.name == "Intergral"
        ) {
          Modal({
            title: "温馨提醒",
            content: "23:00-8:00期间此功能正在维护中...",
          });
          return false;
        }
      }
    });
    //  预览大图
    const previewing = ref(false);
    function closePreview() {
      previewing.value = false;
    }
    function showPreview() {
      previewing.value = true;
    }
    return {
      userInfo,
      previewing,
      showPreview,
      closePreview,
    };
  },
});
